

body {
    background: rgb(255, 255, 255);
    font-family: 'Poppins';
    color: #000;
    margin: 0;
    padding: 0;
  }
  
  .swiper {
      width: 100%;
      height: 100%;
    }
  
  .swiper-slide {
    text-align: center;
    background: rgb(255, 255, 255);
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    object-fit: cover;
  }
  
  .swiper-pagination-bullet{
      background-color: #A71830 !important;
      width: 1rem !important;
      height: 1rem !important;
      border-radius: .5rem !important;
  }
  .swiper-wrapper{
      margin-bottom: 100px;
  }
  
  .swiper-button-prev{
    color: #A71830 !important;
  }
  .swiper-button-next{
    color: #A71830 !important;
  }
  
  
  