@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--red);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

* {
  font-family: 'Poppins', sans-serif;
}

.pointer {
  cursor: pointer;
}

.mh-300 {
  max-height: 300px;
}

.top-10 {
  top: 10px;
}

.top-140 {
  top: 140px;
}

.top-240 {
  top: 460px;
}

.border-gold {
  border-color: var(--gold) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1::before {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background-color: var(--red);
  width: 50%;
  height: 2px;
}

h1::after {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
  background-color: var(--red);
  width: 4px;
  height: 6px;
}

.ofertas {
  margin-top: 150px;
}

.ofertas h2 {
  color: #A71830;
}

button {
  border: 1px solid var(--gold);
}

.text-red {
  color: var(--red) !important;
}

.right-20 {
  right: 20px !important;
}

.bg-header {
  background: url('https://fada-wine.s3.amazonaws.com/HeaderFada.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

.mw-120 {
  max-width: 120px;
}

.bg-gold {
  background-color: #C1A059;
}

.h-100vh {
  height: 100vh;
}

.zIndex-2 {
  z-index: 2 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html,
body {
  margin: 0;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #ffffff;
  transition: color 0.2s;
}

.bm-item:focus-visible {
  outline: none;
}

.scale {
  transition: .4s ease;
}

.scale:hover {
  transform: scale(1.04);
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #FFF;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #FFF;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.btn-gold {
  border: 3px solid var(--gold);
  transition: .3s ease;
}

.btn-gold:hover {
  border: 1px solid var(--gold);
  color: var(--gold);
  transform: scale(1.04);
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.card-group div {
  cursor: pointer;
  transition: .4s ease;
}

.card-group div:hover {
  transform: scale(1.04);
}

.screen_home {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.cart {
  border: 1px solid #555;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 5px;
}

.btn-outline-gold {
  background-color: transparent;
  border: 1px solid #DD9F31 !important;
}

.btn-outline-gold :focus,
.btn-outline-gold :hover,
.btn-outline-gold :active {
  background-color: rgba(#DD9F31) !important;

}

.catalogo {
  padding-top: 120px;
}

.inside {
  margin-top: 200px;
}

.bgCatalogo {
  margin-left: 170px;
  margin-top: 20px;
}

.compra {
  margin-top: 250px;
  margin-left: 100px;
}

.marginInside {
  margin-top: 150px;
}

.w-content {
  width: fit-content !important;
}

.filter {
  position: relative;
  display: inline-block;
}

.filter__button {
  border-radius: 0.125rem;
  padding: 0.5rem 1rem;
  background-color: #2b7de9;
  background-image: none;
  cursor: pointer;
  border: transparent;
  font-weight: 600;
  color: white;
  font-size: 16px;
  line-height: 1.5;
}

.filter__button:hover {
  background-color: #176dde;
}

.filter__button:focus {
  outline: 1px dotted;
  outline: 1px auto -webkit-focus-ring-color;
}

.zIndex {
  z-index: 10 !important;
}

.mw-120 {
  max-width: 120px;
}

.mh-150 {
  max-height: 150px;
}

.alert-card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-opacity {
  background-color: rgba(100, 100, 100, 0.3);
}

@media screen and (max-width: 767px) {

  #sidebar {
    background-color: rgba(193, 160, 89, 0.9) !important;
  }

}

.mw-240 {
  max-width: 240px;
}

.mh-300 {
  max-height: 300px;
}

#labelFilter{
  margin-top: 0.85rem;
}
#navbarNav{
  margin-left: 30% ;
}


@media screen and (max-width: 767px) {
  #navbarNav{
    margin-left: 0% !important;
  }
}

@media screen and (max-width: 767px) {
  #brandNav{
    margin-top: 0%;
    margin-bottom: 0% !important;
  }
  #img-navbar{
    max-height: 120px !important;
    margin-top: -5%;
  }
}
@media screen and (min-width: 768px) {
  #img-navbar{
    max-height: 90px !important;
    margin-left: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  #h3Nav{
    margin-top: 1.75rem;
  }
}
@media screen and (max-width: 767px) {
  #h3Nav{
    margin-top: 1.25rem;
  }
}





